import React, { useState, useContext } from "react";
import loginImg from "../../login.svg";
import axios from 'axios'
// import {AuthApi} from "../../AuthApi";
import AuthApi from "../../AuthApi";

import Cookies from 'js-cookie'
// import {Link} from 'react-router-dom';
// import { useHistory } from "react-router-dom";

export class Login extends React.Component {
  static contextType = AuthApi;

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
    }
    this.onSubmit=this.onSubmit.bind(this)

  }
  
  // componentDidMount() {
  //   const{auth,setAuth}=this.context
  //   // console.log("MOUNTED LOGIN :",auth)
  // }

  onSubmit(event) {
    // console.log("uhhh ", this.context)
    const {auth,setAuth}=this.context
    // const {auth}=this.context

    console.log("Auth before press in login:  ", auth)
    

    event.preventDefault()
    const registered = {
      username: this.state.username,
      password:this.state.password
    }

    axios.post('/app/login', registered)
    .then(response => {console.log(response)
      if(response.data.status==="ok") {
        console.log("Got the token", response.data.data)
        // this.context.auth = true

        // console.log("auth is ",auth)
        // auth.auth=true
        this.context.setAuth(true)
        setAuth(true)


        // console.log("Auth after press in login:  ",this.context.auth)
        Cookies.set("user","loginTrue")
        Cookies.set("logged_in_as", response.data.data.logged_in_as)

      }
      else{
        // alert(response.data.error)
        this.props.inv_pop()
      }
     })
    
    
  }

  setUsername(e) {
    this.setState({username:e.target.value})
  }
  setPassword(e) {
    this.setState({password:e.target.value})
  }

  render() {

    return (
      // <UserConsumer>
      <div className="base-container" ref={this.props.containerRef}>
        <div className="header">Login</div>
        <div className="content">
          <div className="image">
            <img src={loginImg} />
          </div>
          {/* <Link to="/dashboard"> */}
            <form className="form" id="myform2" onSubmit={this.onSubmit}>
              <div className="form-group">
                <label htmlFor="username">Username</label>
                {/* <input type="text" name="username" placeholder="username" onChange={(e)=> this.setState({username: e.target.value})} */}
                {/* <input type="text" name="username" placeholder="username" onChange={(e)=> this.setUsername(e)} */}
                <input type="text" name="username" placeholder="username" 
                value={this.state.username}
                onChange={this.setUsername.bind(this)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                {/* <input type="password" name="password" placeholder="password" onChange={(e)=> this.setState({password: e.target.value})} */}
                <input type="password" name="password" placeholder="password" 
                value={this.state.password}
                onChange={this.setPassword.bind(this)}
                />
              </div>
            </form>
          {/* </Link> */}
        </div>
        <div className="footer">
        <button type="submit" className="btn" form="myform2" value="submit">
            Login
        </button>
        </div>
      </div>
      
    );
  }
}
// Login.contextType=React.useContext(Auth);


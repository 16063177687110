import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import axios from 'axios'
import Cookies from 'js-cookie';

// import { PromiseProvider } from 'mongoose';

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    minWidth: 200,

  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

// export default function OutlinedCard(prop) {
  
  const OutlinedCard = (prop) => {
    // const removecoin = () => {
    //   console.log(prop.prop.symbol)
    //   prop2.remcoin(prop.prop.symbol)
    //   // console.log(prop)
    //   //remove from frontend

    //   //remove from backend
    // }

    
    

    function removecoin() {      


      //remove from frontend
      prop.setusercoindata(prop.usercoindata.filter(item => item.symbol !== prop.prop.symbol))
      //remove from backend
      console.log(prop.usercoindata)
      const toPost = {user:Cookies.get("logged_in_as") ,coin_symbol:prop.prop.symbol}
      axios.post('/app/remove_coin', toPost)
        .then(response => {
          console.log(response)
          
          prop.notifier(prop.prop.symbol)
          
        })

      

    }

  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  // console.log(prop.prop)
  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {/* $ Coin Price USD */}
          ${prop.prop.quote.USD.price.toFixed(2)} (USD)
        </Typography>
        <Typography variant="h5" component="h2">
          {prop.prop.symbol}
          {/* Coin Symbol */}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {/* Coin Name */}
          {prop.prop.name}

        </Typography>
        <Typography variant="body2" component="p">
          Total Circulating Supply: 
          {prop.prop.circulating_supply.toFixed(2)}
          <br />
          24 hr time change
          {prop.prop.quote.USD.percent_change_24h.toFixed(2)} %
        </Typography>
      </CardContent>
      <CardActions>
        {/* <Button size="small" onClick={prop.removecoin(prop.prop.symbol)}>Remove Coin</Button> */}
        {/* <Button size="small" onClick={()=>prop2.removecoin("BRUH")}>Remove Coin</Button> */}
        {/* <Button size="small" onClick={()=>removecoin(prop.prop.symbol)}>Remove Coin</Button> */}
        <Button size="small" onClick={removecoin}>Remove Coin</Button>


        {/* <Button size="small">Remove Coin</Button> */}

      </CardActions>
    </Card>
  );
}
export default OutlinedCard
import Cookies from 'js-cookie';
import { useEffect, useState, useContext } from "react";
import  './Dashboard.css'
import axios from 'axios'
import OutlinedCard from './../../components/card/OutlinedCard'
import {Grid} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import addcoingif from '../../addcoingif.gif'
// import {AuthApi} from "../../AuthApi";
// import AuthApi from "../../AuthApi";

const dontenv = require('dotenv')
dontenv.config()

const useStyles = makeStyles({
    gridContainer: {
        paddingTop: "20px",
        paddingBottom: "20px",
        paddingLeft: "20px",
        paddingRight: "20px",
    }
  });


const Dashboard = () => {
    // const {auth, setAuth} = useContext(AuthApi)
    const classes = useStyles();
    const [cookiedata, setcookiedata] = useState([]);
    const [usercoindata, setusercoindata] = useState([]);
    // const [loading, setloading] = useState([true]);

    var loggedinas = Cookies.get("logged_in_as")
    useEffect(() => {
        // console.log("DASHBOARD ", auth)
        // setAuth(true)
        // console.log("loggedinas", loggedinas)

        
        console.log("loggedinas", loggedinas)
        axios.post('/app/dashboardcoins', {usercoinpostdata: loggedinas })
        .then(response => {
            console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA")
            console.log(response)
            if(response.data.status === "nocoinstofetch") {
                const cookie = Cookies.get("logged_in_as")
                setcookiedata(cookie)
                setusercoindata([])
            }
            else {
                const cookie = Cookies.get("logged_in_as")
                setcookiedata(cookie)
                console.log("$$$$$$$$$$$$$$$$$$$$$$$")
                // console.log(response.data)
                // console.log(Object.values(response.data))
                // console.log(typeof(response.data))


                setusercoindata(Object.values(response.data))
            }
            
        })        
      }, [loggedinas]);
    
    
    let notifier = (e) => {
        console.log(e)
        NotificationManager.success(e +" Removed", "Success");
    }
    let addcoinnotif = (e) => {
        console.log(e)
        NotificationManager.info('Add some coins!');
    }

    if(usercoindata.length===0) {
    // if(1) {
        // addcoinnotif()
        // console.log(loading)
        return (
            <div id="nocoinwrapper">
                <h1 id="welcome">You currently have nothing tracked {cookiedata}, go add some! </h1>
                {/* <p id="mesg">This is embarassing...</p> */}
                {/* <img src={addcoingif} alt="loading..." className="gif"/> */}
                <div id="gifdiv"> 
                    <img src={addcoingif} alt="loading..." id="addcoingif"/>
                </div>
                <div id="notif">
                    <NotificationContainer/>
                </div>
            </div>
            
        )
    }
    

    return (
    <div id="dbwrapper">
        <div className="gridiv">
            <h1 id="welcome">Welcome back, {cookiedata}!</h1>
            <p id="mesg">Your Tracked Coins:</p>
            <Grid container spacing={2} className={classes.gridContainer} justify="center">
                {usercoindata
                .map((item) => {
                    
                    return (
                        <Grid item xs={12} sm={6} md={3}> 
                            {/* <OutlinedCard item={item} remcoin={remmcoin}/> */}
                            <OutlinedCard prop={item} setusercoindata={setusercoindata} usercoindata={usercoindata} notifier={notifier}/>
                            {/* <OutlinedCard props={item, }/> */}

                        </Grid>
                    )
                })
                
                }

            </Grid>
        </div>
        <div id="notif">
            <NotificationContainer/>
        </div>
        
     </div>
     
     
     )
    

}
export default Dashboard
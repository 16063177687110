import  './landing.css'
// import { Link } from 'react-router';
import {Link} from 'react-router-dom';
import bitcoingif from '../../bitcoincrop.gif'
const landing = () => {
    return (
        <div id="maindiv">
            <div id="contentdiv">

                <div>
                    <h1 id="maintitle">
                        Track the coins you want to track!
                    </h1>
                    <h2 id="subtitle">
                        {/* CryptoTrackerr.tk keeps updated prices and info on coins you want to see!  */}
                        CryptoTracker.ga keeps updated prices and info on coins you want to see! 
                        This website uses cookies to keep you logged in!
                    </h2>
                    <Link to="/logsign">
                        <button id="join_now" href="/logsign">
                            Join Now!
                        </button>
                    </Link>
                </div>
                <div>
                    <h1 id="slogan">
                        Personalized for you!
                    </h1>
                    <img src={bitcoingif} alt="loading..." className="gif"/>
                </div>
                
            
            </div>
        </div>
    )

}
export default landing
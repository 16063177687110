import React, { Component } from 'react'
import { MenuItems } from "./MenuItems"
import './Navbar.css'
import { Button } from "../Button"
// import {AuthApi} from "../../AuthApi";
import AuthApi from "../../AuthApi";
import Cookies from 'js-cookie'
// import { Redirect } from 'react-router';
import {Link} from 'react-router-dom';


class Navbar extends Component {
  static contextType = AuthApi;
  constructor(props) {
    super(props)
    this.logoot=this.logoot.bind(this)
  }
  state = { clicked:false }
  handleClick = () => {
    // this.setState({ clicked: !this.state.clicked })
    this.setState({ clicked: true })

  }

  logoot() {
    // const{auth,setAuth}=this.context
    console.log("logooooot")
    this.context.setAuth(false)
    Cookies.remove("user")
    Cookies.remove("logged_in_as")
  }

  render() {

    // if(this.state.clicked){
    //   return <Redirect push to="/" />;
    // }

    return(
      <nav className="NavbarItems">
        <Link to="/"className="navbar-logo">
          <h1 id="hi">CryptoTracker<i class="fab fa-btc"></i></h1>
        </Link>
        <div className="menu-icon" onClick={this.handleClick}>
          <i className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>  
        </div>
        <ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
          {MenuItems.map((item, index) => {
              if(item.title === "Log Out") {
                return (
                  <li key={index}>
                    <a className={item.cName} href="logsign" onClick={this.logoot}>
                      {item.title}
                    </a>
                  </li>
                )
              }
              else {
                return (
                  <li key={index}>
                    <a className={item.cName} href={item.url}>
                      {item.title}
                    </a>
                  </li>
                )
              }
          })}
          
        </ul>
        <Button>Sign Up</Button> 
        {/* onClick={this.signup} */}
      </nav>
    )
  }
}

export default Navbar
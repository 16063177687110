import  './News.css'
// import { Link } from 'react-router';
import { Select, Typography, Row, Col, Avatar, Card } from 'antd';
import React, { useState } from 'react';
import { useGetCryptosQuery } from '../../services/cryptoApi';
import { useGetCryptoNewsQuery } from '../../services/cryptoNewsApi'
// import 'antd/dist/antd.css';
const { Text, Title } = Typography;
const { Option } = Select;
const demoImage = 'https://www.bing.com/th?id=OVFT.mpzuVZnv8dwIMRfQGPbOPC&pid=News';
const News = () => {
    const [newsCategory, setNewsCategory] = useState('Cryptocurrency');
    const { data } = useGetCryptosQuery(100);
    const { data: cryptoNews } = useGetCryptoNewsQuery({ newsCategory, count: 12 });
    console.log(cryptoNews)
    if (!cryptoNews?.value) return 'Loading...'
    return (
        <div id="maindiv">
            <div id = "newsdiv">
                <Row gutter={[24, 24]}>
                    {/* {!simplified && ( */}
                        <Col span={24}>
                        <Select
                            showSearch
                            className="select-news"
                            placeholder="Select a Crypto"
                            optionFilterProp="children"
                            onChange={(value) => setNewsCategory(value)}
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            <Option value="Cryptocurency">Cryptocurrency</Option>
                            {data?.data?.coins?.map((currency) => <Option value={currency.name}>{currency.name}</Option>)}
                        </Select>
                        </Col>
                    {/* )} */}
                    {/*news-card,news-image-container,news-title,provider-container, provider-name*/}
                    {cryptoNews.value.map((news, i) => (
                        <Col xs={24} sm={12} lg={8} key={i}>
                        <Card hoverable className="news-card">
                            <a href={news.url} target="_blank" rel="noreferrer">
                                <div className="news-image-container">
                                    <Title className="news-title" level={4}>{news.name}</Title>
                                    <img src={news?.image?.thumbnail?.contentUrl || demoImage} alt="" />
                                </div>
                                <p className="pnews">{news.description.length > 100 ? `${news.description.substring(0, 100)}...` : news.description}</p>
                                <div className="provider-container">
                                    <div>
                                    <Avatar src={news.provider[0]?.image?.thumbnail?.contentUrl || demoImage} alt="" />
                                    <Text className="provider-name">{news.provider[0]?.name}</Text>
                                    </div>
                                    {/* <Text>{moment(news.datePublished).startOf('ss').fromNow()}</Text> */}
                                </div>
                            </a>
                        </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    )

}
export default News
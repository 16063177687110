import React, { Component, useMemo, useEffect, useState} from 'react'
import './App.css';
import Navbar from './components/Navbar/Navbar'
import test from './components/login/test'
import Coins from './components/coin/Coins'
import News from './components/news/News'
import Dashboard from './components/dashboard/Dashboard'
import logsign from './logsign'
import {BrowserRouter as Router, Switch, Route, Redirect, Link} from 'react-router-dom'
// const AuthApi = React.createContext();
// import AuthApi from './AuthApi';
import Cookies from 'js-cookie'
import landing from './components/landing/landing';
// import { AuthApi, AuthApiProvider } from './AuthApi';
import AuthApi from './AuthApi'

function App() {
  const Auth = React.useContext(AuthApi)
  // const [auth, setAuth] = useState(Auth.auth)
  const [auth, setAuth] = useState(false)



  // const readCookie = () => {
  //   const user = Cookies.get("user")
  //   if(user) {
  //     console.log("cookie auth:  ",auth)
  //     setAuth(true)
  //     console.log("wooooooooooooooooooooooooooooooooooooooo ",auth)
  //   }
  // }

  useEffect(() => {
    const readCookie = () => {
      const user = Cookies.get("user")
      if(user) {
        // console.log("cookie auth:  ",auth)
        setAuth(true)
        // console.log("wooooooooooooooooooooooooooooooooooooooo ",auth)
      }
    }
    // Update the document title using the browser API
    // document.title = `You clicked ${count} times`;
    // console.log("APP ",auth)
    // console.log("xxxxxxx",auth)
    readCookie()
    // console.log("Auth.auth   ", Auth.auth)
    // if (Auth.auth) {
    //   setAuth(Auth.auth)
    // }

  },[]);
  // },[Auth.auth]);
  // },[auth]);

 
  return (
    <div className="App">
      <AuthApi.Provider value={{auth, setAuth}}>
      {/* <AuthApiProvider> */}
        <Router>
          <Navbar/>
          <Routes/>
          {/* <Switch>
            <Route path="/" exact component={landing}/>
            <ProtectedRoute path="/dashboard" exact component={Dashboard} auth={Auth.auth} setAuth={Auth.setAuth} aut={auth}/>
            <ProtectedRoute path="/coins" exact component={Coins} auth={Auth.auth} setAuth={Auth.setAuth} aut={auth}/>
            <ProtectedLogin path="/logsign" exact component={logsign} auth={Auth.auth} setAuth={Auth.setAuth} aut={auth}/>
            <Route path="/signup" exact component={test}/>
          </Switch> */}
        </Router>
      </AuthApi.Provider>

    </div>
  );
}

const Routes = () => {
  const Auth = React.useContext(AuthApi)
  console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$",Auth.auth)
  return (
    <Switch>
      <Route path="/" exact component={landing}/>
      <ProtectedRoute path="/dashboard" exact component={Dashboard} auth={Auth.auth} setAuth={Auth.setAuth}/>
      <Route path="/news" exact component={News}/>
      <ProtectedRoute2 path="/coins" exact component={Coins} auth={Auth.auth} setAuth={Auth.setAuth}/>
      {/* <Route path="/coins" exact component={Coins}/>  */}
      <ProtectedLogin path="/logsign" exact component={logsign} auth={Auth.auth} setAuth={Auth.setAuth}/>
      {/* <Route path="/logsign" exact component={logsign}/> */}
      <Route path="/signup" exact component={test}/>
    </Switch>
  )
}


const ProtectedRoute2 = ({auth, aut, component:Component,...rest}) =>{
  console.log("protectedRoute auth:  ",auth)
  console.log("FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF")
  // const Auth = React.useContext(AuthApi)
  // const aut=Auth.auth
  console.log("protectedRoute opooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooauth:  ",aut)
  // let au=auth
  let au = Cookies.get("user")
  return(
    <Route
    {...rest}
    render={(props)=> au? (
      //true
      <Component {...props}/>
      
    ):
    (
      // false
      <Redirect to={{
        pathname:"/logsign",
        state: {auth:au}
      }}
        
      />
    )
  }
    />
  )
}


const ProtectedRoute = ({auth, aut, component:Component,...rest}) =>{
  console.log("protectedRoute auth:  ",auth)
  console.log("FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF")
  // const Auth = React.useContext(AuthApi)
  // const aut=Auth.auth
  console.log("protectedRoute opooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooauth:  ",aut)

  return(
    <Route
    {...rest}
    render={(props)=> auth? (
      //true
      <Component {...props}/>
      
    ):
    (
      // false
      <Redirect to={{
        pathname:"/logsign",
        state: {auth:auth}
      }}
        
      />
    )
  }
    />
  )
}

const ProtectedLogin = ({auth, aut,component:Component,...rest}) =>{
  console.log("PROTECTED LOGINNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN")
  // const Auth = React.useContext(AuthApi)
  // const aut=Auth.auth
  return(
    <>
    <div>
      {/* auth is {auth} */}
    </div>
    <Route
    {...rest}
    render={()=> !auth? (
      <Component/>
    ):
    (
      <Redirect to="dashboard"/>
    )
  }
    />
    </>
  )
}

export default App;

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import axios from 'axios';
import { Provider } from 'react-redux'
import store from './app/store'
// import 'antd/dist/antd.css';

//eb
// axios.defaults.baseURL = 'http://cryptobackend-env.eba-mqtkc2ch.us-west-2.elasticbeanstalk.com/';
axios.defaults.baseURL = 'https://cryptotrackers.net';


//dev
// axios.defaults.baseURL = 'http://localhost:4000';

// axios.defaults.baseURL = 'http://172.31.13.26:4000';
// axios.defaults.baseURL = 'http://127.0.0.1:4000';

//deploy
// axios.defaults.baseURL = 'https://cryptotrackerr.tk:3443';
// axios.defaults.baseURL = 'https://cryptotracker.ga:3443';




// axios.defaults.baseURL = 'http://localhost:5000';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
import React, { useState } from "react";
import loginImg from "../../login.svg";
import axios from 'axios'

export class Register extends React.Component {
  constructor(props) {
    super(props);
    console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@", this.props)
    // console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@", this.props.containerRef)
    // console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@", this.containerRef)

    this.state = {
      username: '',
      email: '',
      password: '',
    }
    this.onSubmit=this.onSubmit.bind(this)
    this.setState=this.setState.bind(this)
  }

  onSubmit(event) {
    event.preventDefault()
    const registered = {
      username: this.state.username,
      email: this.state.email,
      password:this.state.password
    }

    axios.post('/app/signup', registered)
    // .then(response => console.log(response), )
    .then(response => {console.log(response)
      // console.log(response.data.status)
      if(response.data.status==="ok") {
      console.log("Successful Post Req")
      //ROUTE TO LOGIN
      this.props.changetolog()
      this.props.registered_pop()

      }
      else if(response.data.status==="username exist error") {
        this.props.username_exists_pop()
      }
      else{
        alert(response.data.error)
      }
     })
    
    

    
    
    // this.setState = ({
    //   username: '',
    //   email: '',
    //   password: '',
    // })
  }
  
  render() {
    // const [usernameReg, setUsernameReg] = useState('');
    // const [passwordReg, setPasswordReg] = useState('');
    // function setusername(a, state) {
    //   this.setState({username: a.target.value})
    //   console.log(a.target.value)
    // }
    
    return (
      <div className="base-container" ref={this.props.containerRef}>
        <div className="header">Register</div>
        <div className="content">
          <div className="image">
            <img src={loginImg} />
          </div>
          {/* <div className="form"> */}
          <form id="myform" className="form" onSubmit={this.onSubmit}>
            <div className="form-group">
              <label htmlFor="username">Username</label>
              <input type="text" name="username" placeholder="username" onChange={(e)=> this.setState({username: e.target.value})}//, ()=> {console.log(this.state.username)}
              value={this.state.username}/>
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" placeholder="email" onChange={(e)=> this.setState({email: e.target.value})}
              value={this.state.email}/>
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input type="password" name="password" placeholder="password" onChange={(e)=> this.setState({password: e.target.value})}
              value={this.state.password}/>
            </div>
          {/* </div> */}
          </form>
        </div>
        <div className="footer">
          <button type="submit" className="btn" form="myform" value="submit" onClick={()=>console.log(this.state.username)}>
            Register
          </button>
        </div>
      </div>
    );
  }
}

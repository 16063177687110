export const MenuItems= [
    // {
    //     title: 'Landing',
    //     url: 'landing',
    //     cName: 'nav-links'
    // },
    {
        title: 'Dashboard',
        url: 'dashboard',
        cName: 'nav-links'
    },
    {
        title: 'News',
        url: 'news',
        cName: 'nav-links'
    },
    {
        title: 'Add Coins',
        url: 'coins',
        cName: 'nav-links'
    },
    {
        title: 'Log Out',
        url: 'home',
        cName: 'nav-links'
    },
    {
        title: 'Sign Up',
        url: 'signup',
        cName: 'nav-links-mobile'
    }
]
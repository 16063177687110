import React from 'react'
const AuthApi = React.createContext();
export default AuthApi;

// import React, {useState} from 'react';
// export const AuthApi = React.createContext({
//     auth: false,
//     setAuth: ()=>{}
// });

// export const AuthApiProvider = (props) => {
//     const setAuth = (auth) => {
//         setState({...state, auth: auth})
//     }

//     const initState = {
//         auth: false,
//         setAuth: setAuth
//     }

//     const [state, setState] = useState(initState)

//     return (
//         <AuthApi.Provider value={state}>
//             {props.children}
//         </AuthApi.Provider>
//     )
// }
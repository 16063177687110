import React, { Component, useCallback } from 'react'
import  './Coins.css'
import MaterialTable from "material-table";
import axios from 'axios'
import { useEffect, useState, useContext} from "react";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
//https://codepen.io/npatel023/pen/eapKPo#!
//https://www.youtube.com/watch?v=-QsdzCs2hCU
// materialtable https://www.youtube.com/watch?v=JvULx3X0Ae4
import TablePagination from '@material-ui/core/TablePagination';
// import { StepButton } from '@material-ui/core';
// import { Button } from "../Button"
import Cookies from 'js-cookie';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
// import {AuthApi} from "../../AuthApi";
// import AuthApi from "../../AuthApi";


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
  });

const Coins = () => {
    // const {auth, setAuth} = useContext(AuthApi)
    const classes = useStyles();
    const [coindata, setCoindata] = useState([]);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    // const [coinstoadd, setcoinstoadd] = useState([]);

    const handleChangePage = (event, newPage) => {
      setPage(newPage)
    }
    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value,10))
      setPage(0)
    }
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, coindata.length - page * rowsPerPage);

    const getCoinData = async() => {
        console.log("sending coin get to backend")
        // console.log(process.env.BACKEND)
        axios.get('/app/coins')
        // axios.get(process.env.BACKEND+'/app/coins')
        // axios.get('http://localhost:4000/app/coins')
        // axios.get('app/coins')

        // .then(response => console.log(response), )
        .then(response => {
            // console.log(response)
            // console.log(response.data.data)
            // console.log(typeof(response.data.data))
            let coinarray=[]
            // for(let i=0; i<50; ++i){
            //     coinarray.push(response.data.data[i])
            // }
            // coinarray=response.data.data.slice(0,50)
            coinarray=response.data.data
            console.log(coinarray)
            setCoindata(coinarray)
         })
    };


    useEffect(() => {
      // setAuth(true)
      // console.log("COINS ", auth)
        getCoinData();
      }, []);
    
    function Foo(props) {
    const addcoin = useCallback(
      ()=> {
        // NotificationManager.info('Info message');
        console.log(props.coinToAdd.symbol)
        const toPost = {user:Cookies.get("logged_in_as") ,coin_symbol:props.coinToAdd.symbol}
        // axios.post('http://localhost:4000/app/addcoins', toPost)

        axios.post('/app/addcoins', toPost)
        .then(response => {
          console.log(response)
          NotificationManager.success(props.coinToAdd.symbol +" Added", "Success");
          
        })
      },
      [],
    );
    // const addcoinalert = () => {
    //   NotificationManager.info('Info message');
    // }
    return <button className="btn" align="center" onClick={addcoin}>+</button>;
    }
    
    return (
      <div id="coinwrapper">
        <div className="Coins">
            
            <input
                className="field"
                type="text"
                placeholder="Search Coins"
                onChange={(e) => {
                setSearch(e.target.value);
                setPage(0)
                }}
            />
            {/* <MaterialTable
                data={coindata.filter(x=>x.name)}
                columns={[
                    {
                        // field: 'what',
                        title: 'Coin',
                    }
                ]}
                // columns={coindata}
            >hello</MaterialTable> */}
            <TableContainer component={Paper} className="tc">
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Coin</StyledTableCell>
                            <StyledTableCell align="right">Coin Price</StyledTableCell>
                            <StyledTableCell align="center">Add Coin</StyledTableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {coindata
                        .filter((item) => {
                            if (search === "") {
                            return item;
                            } else if (
                            item.name.toLowerCase().includes(search.toLowerCase())
                            ) {
                            return item;
                            }
                        })
                        .slice(page*rowsPerPage, page*rowsPerPage+rowsPerPage)
                        .map((item) => {
                            // console.log(item.quote.USD.price)
                            return (
                            <StyledTableRow key={item.id}>
                                <StyledTableCell component="th" scope="row">
                                {item.name}
                                </StyledTableCell>
                                <StyledTableCell align="right"> 
                                {item.quote.USD.price}
                                </StyledTableCell>
                                <StyledTableCell align="center"> 
                                  {/* <Button align="center">+</Button> */}
                                  {/* <button className="btn" align="center" onClick={ () => {console.log("heelo")}}>+</button> */}
                                  <Foo coinToAdd={item}/>
                                </StyledTableCell>

                                {/* <button label="Approved" primary={true} onClick={()=>{console.log("!23")}}/> */}
                            </StyledTableRow>
                            
                            );
                        })}
                        {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                        )}
                    </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5,10,25]}
                  component="div"
                  count={coindata.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </TableContainer>

        </div>
        <div>
          {/* <h1>
            alerts
            <NotificationContainer id="notif"/>

          </h1> */}
          <div id="notif">
            <NotificationContainer/>
          </div>

        </div>
        {/* <NotificationContainer id="notif"/> */}
      </div>
    )
}

export default Coins
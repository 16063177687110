import React from "react";
import "./logsign.scss";
import { Login, Register } from "./components/login/index";
// import {AuthApi} from "./AuthApi";
// import AuthApi from "./AuthApi";

// import Cookies from 'js-cookie'
import {NotificationContainer, NotificationManager} from 'react-notifications';


class logsign extends React.Component {
  // static contextType = AuthApi
  constructor(props) {
    super(props);
    this.changeState2 = this.changeState2.bind(this)
    this.state = {
      isLogginActive: true,
      invalid_popup: false
    };
    // console.log("logsign props:    ", this.props)
  }

  componentDidMount() {
    //Add .right by default
    this.rightSide.classList.add("right");
    // console.log("logsign props:    ", this.props)
    // console.log("logsign props:    ", Object.getOwnPropertyNames(this.props))
    // console.log("Auth? in logsign:    ", this.context.auth)
  }
  changeState2() {
    const { isLogginActive } = this.state;

    if (isLogginActive) {
      this.rightSide.classList.remove("right");
      this.rightSide.classList.add("left");
    } else {
      this.rightSide.classList.remove("left");
      this.rightSide.classList.add("right");
    }
    this.setState(prevState => ({ isLogginActive: !prevState.isLogginActive }));
  }
  changeState() {
    const { isLogginActive } = this.state;

    if (isLogginActive) {
      this.rightSide.classList.remove("right");
      this.rightSide.classList.add("left");
    } else {
      this.rightSide.classList.remove("left");
      this.rightSide.classList.add("right");
    }
    this.setState(prevState => ({ isLogginActive: !prevState.isLogginActive }));
  }

  inv_pop() {
    // const { invalid_popup } = this.state;
    // this.setState({
    //   invalid_popup:true
    // })
    NotificationManager.error("Invalid Login!")
  }
  registered_pop() {
    NotificationManager.success("Registered, Now Log In!")
  }
  username_exists_pop() {
    NotificationManager.error("Username already exists!")
  }

  render() {
    // console.log("SHEEEEEEEEEESH")
    // // Auth.setAuth(false)
    // Cookies.remove("user")
    const { isLogginActive } = this.state;
    const current = isLogginActive ? "Register" : "Login";
    const currentActive = isLogginActive ? "login" : "register";
    return (
      <div id="maindivbro">
        <div id="cdiv">
          <div className="logsign">
            <div className="login">
              <div className="container" ref={ref => (this.container = ref)}>
                {isLogginActive && (
                  <Login containerRef={ref => (this.current = ref)} inv_pop={this.inv_pop} />
                )}
                {!isLogginActive && (
                  <Register containerRef={ref => (this.current = ref)} changetolog={this.changeState2} registered_pop={this.registered_pop} username_exists_pop={this.username_exists_pop}/>
                )}
              </div>
              <RightSide
                current={current}
                currentActive={currentActive}
                containerRef={ref => (this.rightSide = ref)}
                onClick={this.changeState.bind(this)}
              />
            </div>
          </div>
          <div id="notif">
              <NotificationContainer/>              
          </div>
        </div>
      </div>
    );
  }
}

const RightSide = props => {
  return (
    <div
      className="right-side"
      ref={props.containerRef}
      onClick={props.onClick}
    >
      <div className="inner-container">
        <div className="text">{props.current}</div>
      </div>
    </div>
  );
};

export default logsign;
